<template>
    <st-page :title="title" layout="tab">
        <!-- <loader v-if="isLoading"/> -->
        <b-tabs
            class="st-tabs vertical-tabs"
            justified
            lazy
            vertical
            card
            v-model="tabIndex"
        >
            <b-tab
                v-for="tab in tabs"
                :key="tab.index"
                :title-item-class="navItemClass(tab)"
                :ref="tabReference(tab.index)"
                :disabled="isDisabled[tab.index]"
                :active="!isDisabled[tab.index]"
            >
                <template #title>
                    <div class="d-flex justify-content-left w-100">
                        <i :class="['fas', tab.icon]"></i>
                        <span class="text-uppercase ml-4">{{
                            $t(tab.titleKey)
                        }}</span>
                    </div>
                </template>
                <component 
                    :is="tab.componentType" 
                    @nextStep="nextStep"
                    :totalTaxes="totalTaxes"
                    v-if="!isLoading">
                </component>
            </b-tab>
        </b-tabs>
    </st-page>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SignDetailsStep from "@/modules/applications/components/payment/SignDetailsStep";
import TaxDetailsStep from "@/modules/applications/components/payment/TaxDetailsStep";
import PaymentDetailsStep from "@/modules/applications/components/payment/PaymentDetailsStep";
import SignDocumentsStep from "@/modules/applications/components/payment/SignDocumentsStep";
import SendApplicationStep from "@/modules/applications/components/payment/SendApplicationStep";

export default {
    name: "ApplicationsPayment",
    components: {
        SignDetailsStep,
        TaxDetailsStep,
        PaymentDetailsStep,
        SignDocumentsStep,
        SendApplicationStep,
    },
    props: {
        id: String,
    },
    data() {
        return {
            tabIndex: 0,
            totalTaxes: {
                taxes: 0,
                signTaxes: 0
            },
            tabs: [
                {
                    index: 0,
                    name: "sign-details",
                    titleKey: "APPLICATION.PAYMENT.TABS.SIGN_DETAILS",
                    buttonText: "Continua",
                    componentType: "SignDetailsStep",
                    icon: "fa-check-circle",
                    disabled: false,
                },
                {
                    index: 1,
                    name: "tax-details",
                    titleKey: "APPLICATION.PAYMENT.TABS.TAX_DETAILS",
                    buttonText: "Continua",
                    componentType: "TaxDetailsStep",
                    icon: "fa-check-circle",
                    disabled: true,
                },
                {
                    index: 2,
                    name: "sign-documents",
                    titleKey: "APPLICATION.PAYMENT.TABS.SIGN_DOCUMENTS",
                    buttonText: "Continua",
                    componentType: "SignDocumentsStep",
                    icon: "fa-check-circle",
                    disabled: true,
                },
                {
                    index: 3,
                    name: "send-application",
                    titleKey: "APPLICATION.PAYMENT.TABS.SEND_APPLICATION",
                    buttonText: "Continua",
                    componentType: "SendApplicationStep",
                    icon: "fa-check-circle",
                    disabled: true,
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            loading: "shared/loading",
            currentUser: "auth/currentUser",
            record: "applications/form/record"
        }),
        isLoading() {
            return this.loading['applications/find'];
        },
        title() {
            return this.$t('APPLICATION.FORM.WIZARD_TITLE');
        },
        isDisabled() {
            let disabledTab = [];
            this.tabs.forEach((el) => {
                disabledTab.push(el.disabled);
            });

            return disabledTab;
        }
    },
    methods: {
        ...mapActions({
            doFind: "applications/form/find",
        }),
        navItemClass(tab) {
            if (tab?.disabled) return "";
        },
        tabReference(index) {
            return "step" + index;
        },
        nextStep(params) {
            let currentTab = this.tabs.find((el) => el.index === this.tabIndex);
            currentTab.disabled = false;

            this.tabIndex++;
            let nextTab = this.tabs.find((el) => el.index === this.tabIndex);
            this.isDisabled[nextTab.index] = false;

            if (params?.taxes) {
                this.totalTaxes = params;
            }
        },
    },
    async created() {
        if (this.id) {
            await this.doFind({
                id: this.id,
                loadStaff: false
            });
        }
    },
};
</script>
