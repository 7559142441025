<template>
    <form class="row">
        <div class="col-md-9">
            <div class="title font-size-h4">Modalitate de semnare</div>
            <div class="info mb-6">
                Pentru depunderea cererii online, aceasta va fi semnata digital
                prin semnatura electronica. Procesul de semnare se poate face de
                la distanta, iar pentru aceasta aveti nevoie de un certificat
                digital.
            </div>

            <div class="card card-sm bg-light-gray">
                <div class="form-group">
                    <label class="font-weight-bold">Certificat digital</label>
                    <b-form-group>
                        <b-form-radio-group
                            v-model="model.certsign"
                            :options="certsignOptions"
                            name="certsign">
                        </b-form-radio-group>
                    </b-form-group>
                </div>

                <div class="form-group card card-sm">
                    <label class="font-weight-bold">Selecteaza beneficiar</label>
                    <hr />
                    <b-form-radio
                        v-model="model.applicant"
                        name="applicant"
                        :value="true">
                        <span class="d-block font-weight-bold">Beneficiar</span>
                        <span>{{ fullName }} ({{model.user.phone_number}})</span
                        >
                    </b-form-radio>
                    <hr />

                    <span class="info font-weight-bold d-block">Certificate digitale online</span>
                    <span>Vei plati cu cardul in pasul imediat urmator</span>

                    <div class="form-group mt-4">
                        <b-form-group>
                            <b-form-radio
                                v-model="model.signValue"
                                value="10"
                                name="signValue">
                                Certificat digital 10
                                <span class="d-block font-size-sm">Pachet semnatura electronica cu 10 semnaturi incluse</span>
                            </b-form-radio>
                            <b-form-radio
                                v-model="model.signValue"
                                value="20"
                                name="signValue">
                                Certificat digital 20
                                <span class="d-block font-size-sm">Pachet semnatura electronica cu 20semnaturi incluse</span>
                            </b-form-radio>
                        </b-form-group>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-end mt-6">
                <st-button :callback="nextStep">
                    Pasul urmator
                </st-button>
            </div>
        </div>
    </form>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "SignDetailsStep",
    data() {
        return {
            model: {
                signValue: 10,
                certsign: true,
                applicant: true,
            },
            certsignOptions: [
                {
                    value: true,
                    text: "Nu am certificat digital",
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            record: "applications/form/record",
        }),
        fullName() {
            return this.model.user.first_name + " " + this.model.user.last_name;
        },
    },
    methods: {
        nextStep() {
            //do something with model
            this.$emit("nextStep", {certsign: this.model.certsign});
        },
    },
    created() {
        this.model.user = this.record.applicant_user;
    },
};
</script>
