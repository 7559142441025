<template>
    <div class="row">
        <div class="tax-details col-md-9">
            <div class="title font-size-h4">Sumar plata</div>

            <div class="card card-sm bg-light-gray">
                <div class="d-flex flex-row justify-content-center mb-6">
                    <div class="col-md-4 card card-sm mr-1">
                        <label class="font-weight-bold font-size-sm">
                            <i class="far fa-check-circle mr-2"></i>Modalitate
                            semnare
                        </label>
                        <div class="font-size-sm">
                            <span class="font-weight-bold">Semnare cu certificat digital online</span>
                            <span class="d-block mt-2">
                                {{ fullName }} ({{
                                    record.applicant_user.phone_number
                                }}}<br />
                                Certificat digital 10
                            </span>
                        </div>
                    </div>
                    <div class="col-md-4 card card-sm mr-1">
                        <label class="font-weight-bold font-size-sm">
                            <i class="far fa-check-circle mr-2"></i>Beneficiar
                        </label>
                        <div class="font-size-sm">
                            <span class="font-weight-bold">Persoana fizica</span>
                            <span class="d-block mt-2">
                                {{ fullNameBeneficiary }} ({{
                                    record.form.beneficiary_users[0].phone_number
                                }}}<br />
                                {{ beneficiaryAddress }} <br />
                                {{ record.form.beneficiary_users[0].email }}
                            </span>
                        </div>
                    </div>
                    <div class="col-md-4 card card-sm">
                        <label class="font-weight-bold font-size-sm"
                            ><i class="far fa-check-circle mr-2"></i>Modalitate
                            de plata</label
                        >
                        <div class="font-size-sm">
                            <span class="font-weight-bold">Card online</span>
                            <span class="d-block mt-2">
                                Dupa initierea platii, vei fii redirectionat
                                catre procesatorul de plati pentru a efectua
                                plata.
                            </span>
                        </div>
                    </div>
                </div>

                <div class="flex-row mb-6">
                    <div class="card card-sm flex-fill">
                        <label class="mb-4">
                            <i class="far fa-check-circle mr-2"></i>Taxe si
                            avize
                        </label>
                        <hr/>
                        <div
                            class="row font-size-sm"
                            v-for="(tax, index) in taxes"
                            :key="index"
                        >
                            <div class="col-md-10 pb-4">
                                1 x {{ tax.description }}
                            </div>
                            <div
                                class="col-md-2 d-flex justify-content-end pb-4"
                            >
                                {{ tax.value }} lei
                            </div>
                        </div>
                        <div class="row total-row font-size-sm">
                            <div class="col-md-10 pt-4 ">Total</div>
                            <div class="col-md-2 d-flex justify-content-end">
                                {{ total }} lei
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex-row">
                    <div class="card card-sm">
                        <label class="mb-4">
                            <i class="far fa-check-circle mr-2"></i>Semnatura
                            electronica
                        </label>
                        <hr/>
                        <div class="row font-size-sm">
                            <div class="col-md-10 pb-4">
                                1 x {{ signTaxes.description }}
                            </div>
                            <div
                                class="col-md-2 d-flex justify-content-end pb-4"
                            >
                                {{ signTaxes.value }} lei
                            </div>
                        </div>
                        <div class="row total-row font-size-sm">
                            <div class="col-md-10 pt-4">Total</div>
                            <div class="col-md-2 d-flex justify-content-end">
                                {{ signTaxes.value }} lei
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex-row d-flex justify-content-center mt-6">
                    <div class="col-md-4 font-weight-bold font-size-lg">Total: {{ grandTotal }} lei</div>
                </div>
            </div>

            <div class="d-flex justify-content-end mt-6">
                <st-button :callback="nextStep">
                    Plateste
                </st-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Message from "@/shared/message/message";

export default {
    name: "TaxDetailsStep",
    data() {
        return {
            taxes: [],
            total: 0,
            signTaxes: {
                description: "Certificat digital - 10 semnari",
                value: 35,
            },
        };
    },
    computed: {
        ...mapGetters({
            record: "applications/form/record",
        }),
        fullName() {
            return (
                this.record.applicant_user.first_name +
                " " +
                this.record.applicant_user.last_name
            );
        },
        fullNameBeneficiary() {
            return (
                this.record.form.beneficiary_users[0].first_name +
                " " +
                this.record.form.beneficiary_users[0].last_name
            );
        },
        beneficiaryAddress() {
            const beneficiary = this.record.form.beneficiary_users[0];
            return (
                beneficiary.street +
                " " +
                beneficiary.street_number +
                ", " +
                beneficiary.locality +
                ", " +
                beneficiary.county
            );
        },
        grandTotal() {
            return this.total + this.signTaxes.value;
        },
    },
    methods: {
        ...mapActions({
            calculateTaxes: "applications/form/calculateTaxes",
            updatePaymentStatus: 'applications/form/updatePaymentStatus',
        }),
        async nextStep() {
            //do something here
            const payload = {
                id: this.record.id,
                is_paid: true,
            };

            await this.updatePaymentStatus(payload);
            Message.success('APPLICATION.PAYMENT.SUCCESS_MESSAGE');
            this.$emit('nextStep', {
                taxes: this.total,
                signTaxes: this.signTaxes.value
            });
        },
    },
    mounted() {
        //get tax details
        const payload = {
            id: this.record.application_type_id,
            land_area: this.record.real_estate_target.land_area,
            territorial_administrative_unit_id: this.record.real_estate_target.territorial_administrative_unit_id,
        };

        this.calculateTaxes(payload).then((response) => {
            this.taxes.push(response);

            //fake data
            const fakeData = [
                {
                    description: "Aviz Primar",
                    value: 10,
                },
                {
                    description: "Extras Carte Funciara",
                    value: 20,
                },
                {
                    description: "Extras Plan Cadastral",
                    value: 20,
                },
            ];

            fakeData.forEach((item) => this.taxes.push(item));

            this.total = this.taxes.reduce(
                (acc, current) => acc + current.value,
                0
            );
        });
    },
};
</script>
