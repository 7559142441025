<template>
    <form class="col-md-5">
        <div class="title">Date card</div>

        <div class="form-group">
            <label>Numarul cardului</label>
            <st-input-text ref="cardNo" name="cardNo" />
        </div>

        <div class="row">
            <div class="col-sm">
                <div class="form-group">
                    <label>Data expirarii</label>
                    <st-input-text ref="dateExp" name="dateExp" />
                </div>
            </div>
            <div class="col-sm">
                <div class="form-group">
                    <label>Cod securitate</label>
                    <st-input-text ref="code" name="code" />
                </div>
            </div>
        </div>

        <div class="form-group">
            <label>Nume si prenume</label>
            <st-input-text ref="fullName" name="fullName" />
        </div>

        <div class="d-flex justify-content-end">
            <st-button :callback="nextStep">
                Plateste
            </st-button>
        </div>
    </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    name: "PaymentDetailsStep",
    computed: {
        ...mapGetters({
            record: "applications/form/record",
        }),
    },
    methods: {
        ...mapActions({
            updatePaymentStatus: 'applications/form/updatePaymentStatus',
        }),
        async nextStep() {
            const payload = {
                id: this.record.id,
                is_paid: true,
            };
            await this.updatePaymentStatus(payload);
            this.$emit('nextStep', {});
        }
    }
};
</script>
