<template>
    <div class="row">
        <Loader v-if="isLoading"></Loader>

        <div class="tax-details col-md-9">
            <div class="title font-size-h4">Depune cererea</div>

            <div class="card card-sm bg-light-gray">
                <div class="d-flex flex-row justify-content-center mb-6">
                    <div class="col-md-4 card card-sm mr-1">
                        <label class="font-weight-bold font-size-sm">
                            <i class="far fa-check-circle mr-2"></i>Modalitate
                            semnare
                        </label>
                        <div class="font-size-sm">
                            <span class="font-weight-bold"
                                >Semnare cu certificat digital online</span
                            >
                            <span class="d-block mt-2">
                                {{ fullName }} ({{
                                    record.applicant_user.phone_number
                                }})<br />
                                Certificat digital 10
                            </span>
                        </div>
                    </div>
                    <div class="col-md-4 card card-sm mr-1">
                        <label class="font-weight-bold font-size-sm">
                            <i class="far fa-check-circle mr-2"></i>Beneficiar
                        </label>
                        <div class="font-size-sm">
                            <span class="font-weight-bold"
                                >Persoana fizica</span
                            >
                            <span class="d-block mt-2">
                                {{ fullNameBeneficiary }} ({{
                                    record.beneficiary_users[0].phone_number
                                }}}<br />
                                {{ beneficiaryAddress }} <br />
                                {{ record.beneficiary_users[0].email }}
                            </span>
                        </div>
                    </div>
                    <div class="col-md-4 card card-sm">
                        <label class="font-weight-bold font-size-sm"
                            ><i class="far fa-check-circle mr-2"></i>Modalitate
                            de plata</label
                        >
                        <div class="font-size-sm">
                            <span class="font-weight-bold">Card online</span>
                            <span class="d-block mt-2">
                                Cost taxe si avize: {{totalTaxes.taxes}} lei<br />
                                Cost semnatura electronica: {{totalTaxes.signTaxes}} lei<br />
                                Total: {{total}} lei<br />
                                Status plata: Achitat
                            </span>
                        </div>
                    </div>
                </div>

                <div class="flex-row mb-6">
                    <div class="card card-sm flex-fill">
                        <label class="mb-4">
                            <i class="far fa-check-circle mr-2"></i>Documente
                            semnate electronic
                        </label>
                        <hr />
                        <div
                            class="row font-size-sm"
                            v-for="file in files"
                            :key="file.id"
                        >
                            <div class="col-md-10 pb-4">
                                Cerere pentru eliberarea certificatului de urbanism
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-end mt-6">
                <st-button :callback="nextStep">
                    Trimite cererea
                </st-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "SendApplicationStep",
    props: {
        totalTaxes: {
            type: Object,
            default: () => {
                return {
                    taxes: 0,
                    signTaxes: 0
                }
            }
        }
    },
    data() {
        return {
            files: [],
        };
    },
    computed: {
        ...mapGetters({
            fileToken: "applications/form/fileToken",
            record: "applications/form/record",
            loading: "shared/loading",
        }),
        isLoading() {
            return this.loading['applications/signDocumentCitizen']
        },
        fullName() {
            return (
                this.record.applicant_user.first_name +
                " " +
                this.record.applicant_user.last_name
            );
        },
        fullNameBeneficiary() {
            return (
                this.record.beneficiary_users[0].first_name +
                " " +
                this.record.beneficiary_users[0].last_name
            );
        },
        beneficiaryAddress() {
            const beneficiary = this.record.beneficiary_users[0];
            return (
                beneficiary.street +
                " " +
                beneficiary.street_number +
                ", " +
                beneficiary.locality +
                ", " +
                beneficiary.county
            );
        },
        total() {
            return this.totalTaxes.taxes + this.totalTaxes.signTaxes;
        }
    },
    methods: {
        ...mapActions({
            doFetchDocs: "applications/form/findFiles",
            doFind: "applications/form/find",
            finalizeApplication: "applications/form/finalizeApplication",
        }),
        nextStep() {
            //do something with model
            this.finalizeApplication(this.record.id).then(() => {
                this.$router.push({
                    name: 'applications'
                });
            })
        },
    },
    mounted() {
        if (this.record?.files?.length && this.fileToken) {
            this.record.files.forEach((file) => {
                this.doFetchDocs({ id: file.file_id, token: this.fileToken }).then(
                    (response) => {
                        this.files.push(response);
                    }
                );
            });
        }
    },
};
</script>

